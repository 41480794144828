<template>
  <v-card class="elevation-0" style="border-radius:16px;">
    <v-toolbar
      dark
      color="blue darken-3"
      class="mb-1"
      elevation="0"
      style="
        border-top-left-radius: 16 !important;
        border-top-right-radius: 16 !important;
      "
    >
      <span class="font-weight-bold font-size-h4" style="width:15%">{{
        $t("MF.filesLabel")
      }}</span>
      <v-spacer style="width:30%"></v-spacer>
      <v-select
        flat
        solo-inverted
        hide-details
        :items="keys"
        prepend-inner-icon="mdi-magnify"
        :label="$t('MF.sortby')"
        v-model="sortBy"
        class="mr-4"
        v-if="false"
      ></v-select>
      <v-text-field
        v-model="search"
        clearable
        flat
        solo-inverted
        dense
        rounded
        hide-details
        prepend-inner-icon="mdi-magnify"
        :label="$t('MF.search')"
      ></v-text-field>
    </v-toolbar>
    <v-data-table
      :items="itemsSorted"
      :loading="loadingStatus"
      :locale="this.$root.lang"
      style="border-radius:16px;"
      :headers="headers"
      :items-per-page="5"
      :loading-text="$t('tLoading')"
      :search="search"
    >
      <template v-slot:item.directus_files_id.filename_download="{ item }">
        <img
          alt=""
          class="max-h-45px max-w-35px mr-4"
          :src="setFileIcon(item.directus_files_id.type)"
        />
        <span class="text-dark-75 font-weight-bold font-size-sm mr-2">{{
          item.directus_files_id.filename_download
        }}</span>
      </template>
      <template v-slot:item.directus_files_id.filesize="{ item }">
        <span class="text-dark-75 font-weight-bold font-size-sm mr-2">{{
          getReadableFileSizeString(item.directus_files_id.filesize)
        }}</span>
      </template>

      <template v-slot:item.directus_files_id.uploaded_on="{ item }">
        <span class="text-dark-75 font-weight-bold font-size-sm mr-2">{{
          item.directus_files_id.uploaded_on | moment("dddd, MMMM Do YYYY")
        }}</span>
      </template>
      <template v-slot:item.action="{ item }">
        <a
          @click="selectFile(item.directus_files_id)"
          class="btn btn-icon btn-success"
        >
          <em class="flaticon-eye"></em>
        </a>

        <button
          class="btn btn-icon btn-warning mx-3"
          @click="updateRecord(item)"
        >
          <em class="flaticon2-edit"></em>
        </button>

        <button class="btn btn-icon btn-danger" @click="deleteItem(item)">
          <em class="flaticon2-delete"></em>
        </button>
      </template>
    </v-data-table>
    <FileView
      :file="selectedFile"
      :dialog="isViewOpen"
      @fileViewClosed="closeFileView($event)"
    ></FileView>

    <EditFile
      @closeDialog="openAddDialog = false"
      @formSubmitted="formSubmitted"
      :dialog="openAddDialog"
      :record="record"
      v-if="openAddDialog"
    />

    <!-- delete item -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline"
          >Are you sure you want to delete this File?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm()"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import ApiService from "@/core/services/api.service";
//import Avatar from "@/view/pages/utilComponents/avatar2.vue";
import FileView from "./fileView.vue";
import EditFile from "./editcomp/EditFile";

export default {
  name: "Files",
  props: ["mfid"],
  components: { FileView, EditFile },
  data() {
    return {
      openAddDialog: false,
      dialogDelete: false,
      record: {},
      file: null,
      search: "",
      sortBy: "",
      error: "",
      keys: ["All"],
      loadingStatus: true,
      items: [],
      //historySorted:[],
      headers: [
        {
          text: this.$t("MF.files.filename"),
          align: "left",
          sortable: true,
          value: "directus_files_id.filename_download"
        },
        { text: this.$t("MF.files.size"), value: "directus_files_id.filesize" },
        { text: this.$t("MF.date"), value: "directus_files_id.uploaded_on" },
        {
          text: this.$t("MF.action"),
          value: "action",
          align: "right",
          sortable: false
        }
      ],
      lastSelected: null,
      selectedFile: null,
      isViewOpen: false
    };
  },
  mounted() {
    this.getItems();
    //this.getTypes();
  },
  methods: {
    async getItems() {
      //var userInfo = JSON.parse(localStorage.userInfo).id;

      await ApiService.get(
        "items",
        "medical_file_directus_files?fields=id,directus_files_id,directus_files_id.id,directus_files_id.title,directus_files_id.type,directus_files_id.uploaded_by,directus_files_id.filename_download,directus_files_id.filesize,directus_files_id.data,directus_files_id.uploaded_on&filter[medical_file_id]=" +
          this.mfid
      )
        .then(({ data }) => {
          ////console.log(data.data);
          this.items = data.data;
          this.loadingStatus = false;
        })
        .catch(error => {
          this.error = error;
        });
    },
    selectFile(f) {
      //console.log("file:", file);
      this.selectedFile = f;
      this.isViewOpen = true;
    },
    closeFileView(d) {
      this.isViewOpen = d;
    },
    setFileIcon(type) {
      var path = "/media/svg/files/";
      if (type.includes("pdf")) {
        return path + "pdf.svg";
      } else if (type.includes("jpeg") || type.includes("jpg")) {
        return path + "jpg.svg";
      } else if (type.includes("mp4")) {
        return path + "mp4.svg";
      } else {
        return path + "doc.svg";
      }
    },
    getReadableFileSizeString(fileSizeInBytes) {
      var i = -1;
      var byteUnits = [" kB", " MB", " GB", " TB", "PB", "EB", "ZB", "YB"];
      do {
        fileSizeInBytes = fileSizeInBytes / 1024;
        i++;
      } while (fileSizeInBytes > 1024);

      return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
    },

    deleteItem(file) {
      this.file = file;
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      try {
        this.loadingStatus = true;

        await ApiService.delete("files", this.file.directus_files_id.id);

        this.loadingStatus = false;
        this.closeDelete();
        this.file = null;
        this.toast(
          "b-toaster-top-center",
          "success",
          "file deleted successfully"
        );
        this.getItems();
      } catch (error) {
        // console.log(error);
      }
    },

    closeDelete() {
      this.dialogDelete = false;
    },

    updateRecord(record) {
      this.record["id"] = record.directus_files_id.id;
      this.record["filename_download"] =
        record.directus_files_id.filename_download;
      this.openAddDialog = true;
    },
    formSubmitted(msg) {
      this.openAddDialog = false;
      this.toast("b-toaster-top-center", "success", msg);
      this.getItems();
    },
    toast(toaster, v, msg, append = false) {
      this.counter++;
      this.$bvToast.toast(msg, {
        title: `Alert`,
        toaster: toaster,
        solid: true,
        variant: v,
        appendToast: append
      });
    }
    /*async getTypes()
    {
      await ApiService.get("items", "vital_types?fields=id,name")
        .then(({ data }) => {
          ////console.log(data.data);
          for(var i = 0; i<data.data.length;i++)
          {
            this.keys.push(data.data[i].name);
          }
          this.filterloading = false;
        }, error => {
                  ////console.log(error);
                  
        })
    }*/
  },
  computed: {
    itemsSorted() {
      return this.items.filter(i => {
        return (
          !this.sortBy ||
          i.vital_type.name === this.sortBy ||
          this.sortBy == "All"
        );
      });
    }
  }
};
</script>
