<template>
  <v-scroll-x-reverse-transition>
    <div v-if="dialog" class="customfullview">
      <div :style="settoptofixed">
        <v-system-bar
          dark
          color="primary"
          :height="$root.sysbarheight"
          v-if="$root.showBar"
        >
        </v-system-bar>
        <v-toolbar flat dark color="primary">
          <v-btn icon dark @click="close()">
            <v-icon>{{
              $root.lang == "en" ? "mdi-chevron-left" : "mdi-chevron-right"
            }}</v-icon>
          </v-btn>
          <v-toolbar-title v-if="file">{{
            file.filename_download
          }}</v-toolbar-title>
          <v-toolbar-title v-else>Loading</v-toolbar-title>
        </v-toolbar>
      </div>
      <div
        :style="'width:100%;padding-top:' + (65 + $root.sysbarheight) + 'px'"
      >
        <v-card>
          <v-card-text
            v-if="file"
            style="min-height: 90%"
            ref="card"
            class="p-0"
          >
            <div v-if="file.type.includes('pdf')">
              <v-progress-linear
                color="primary"
                class="mt-0"
                :value="loadingRatio"
                height="6"
              ></v-progress-linear>
              <pdf
                v-for="i in totalPages"
                :key="i"
                :src="pdfSrc"
                :page="i"
                style="
              display: block;
              width: 70%;
              margin-right: auto;
              margin-left: auto;
            "
              ></pdf>
            </div>
            <div
              v-if="
                file.type.includes('jpeg') ||
                  file.type.includes('jpg') ||
                  file.type.includes('png')
              "
            >
              <img :src="getURL()" />
            </div>
            <div v-if="file.type.includes('dicom')">
              <div style="position: absolute; z-index: 0; width: 100%">
                <dwv :uri="'https://test.com?input=' + getURL()"></dwv>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </v-scroll-x-reverse-transition>
</template>

<script>
//import ApiService from "@/core/services/api.service";
//import Avatar from "@/view/pages/utilComponents/avatar2.vue";
import pdf from "vue-pdf";
import dwv from "./viewer/dwv.vue";
//import dwv from 'dwv'

export default {
  name: "FileView",
  props: ["file", "dialog"],
  components: { pdf, dwv },
  data() {
    return {
      error: "",
      page: 1,
      totalPages: 0,
      pdfSrc: null,
      loadingRatio: 0,
      canv: this.$refs.canvas,
      settoptofixed:
        "position: fixed;top:0;width: 100%;z-index: 1000;height" +
        (this.$root.sysbarheight + 56) +
        "px"
    };
  },
  mounted() {
    /*window.addEventListener('contextmenu', function (e) { 
  // do something here... 
  e.preventDefault(); 
}, false);*/
  },

  updated() {
    this.canv = this.$refs.canvas;
  },
  methods: {
    close() {
      this.isDoneLoading = false;
      this.pdfSrc = null;
      this.totalPages = 0;
      this.page = 1;
      this.$emit("fileViewClosed", false);
    },
    getURL() {
      return (
        this.file.data.full_url.split("/public/")[0] + this.file.data.asset_url
      );
    }
  },
  watch: {
    dialog: function(newDialog) {
      //console.log("new dialog: ", newDialog, "old dialog", oldDialog);
      if (newDialog) {
        if (this.file.type.includes("pdf")) {
          this.pdfSrc = pdf.createLoadingTask(
            this.file.data.full_url.split("/public/")[0] +
              this.file.data.asset_url
          );
          this.pdfSrc.onProgress = progressData => {
            //console.log(progressData);
            this.loadingRatio =
              (progressData.loaded / this.file.filesize) * 100;
            // console.log("loading ratio: ", this.loadingRatio);
          };
          this.pdfSrc.promise.then(pdf => {
            this.totalPages = pdf.numPages;
          });
        }
      }
    }
  },
  computed: {}
};
</script>
