<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500px"
    v-if="this.$root.mobile"
    :fullscreen="this.$root.mobile"
    transition="dialog-bottom-transition"
    style="min-height:60% !important"
    hide-overlay
  >
    <v-card>
      <!-- <v-card-title>
        <span class="headline">Edit History</span>
      </v-card-title> -->
      <v-toolbar flat dark color="primary">
        <v-btn icon dark @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t("MF.files.new") }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-form ref="filesForm" v-model="valid">
            <v-text-field
              v-model="filename_download"
              label="Summary"
              outlined
              :rules="fileNameRules"
            />
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error darken-1" text @click="close">
          Cancel
        </v-btn>
        <v-btn color="blue darken-1" text @click="submit">
          <b-spinner v-if="submitted" small class="mr-2"></b-spinner>
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="dialog" persistent max-width="700px" v-else>
    <v-card>
      <v-card-title>
        <span class="headline">Edit History</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="filesForm" v-model="valid">
            <v-text-field
              v-model="filename_download"
              label="File  Name"
              outlined
              :rules="fileNameRules"
            />
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error darken-1" text @click="close">
          Cancel
        </v-btn>
        <v-btn color="blue darken-1" text @click="submit">
          <b-spinner v-if="submitted" small class="mr-2"></b-spinner>
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
export default {
  props: ["dialog", "record"],
  data() {
    return {
      error: false,
      valid: false,
      submitted: false,
      errorMsg: "",
      filename_download: "",
      askStarted: false,
      fileNameRules: [
        v => !!v || "File name is required",
        v => v.trim().length >= 3 || "File name must be at least 3 characters"
      ]
    };
  },
  methods: {
    async submit() {
      try {
        if (this.$refs.filesForm.validate()) {
          this.submitted = true;

          await ApiService.update("files", this.record.id, {
            filename_download: this.filename_download
          });
          this.submitted = false;
          this.$refs.filesForm.reset();
          this.$emit("formSubmitted", "File name Updated Successfully");
        }
      } catch (error) {
        if (error.message == "") {
          this.error = error;
        } else {
          this.error = "sorry, something went wrong";
        }
        // console.log(error);
      }
    },
    async getItemTypes() {
      try {
        const { data } = await ApiService.get(
          "items",
          "illnesses?fields=id,name,symptoms"
        );
        this.itemTypes = data.data;
        this.itemTypesLoaded = false;
      } catch (error) {
        this.error = error;
        // console.log(error);
      }
    },

    typeFilter(item, queryText) {
      return item.type.toLowerCase().includes(queryText.toLowerCase());
    },
    close() {
      this.$emit("closeDialog");
    }
  },
  mounted() {
    this.getItemTypes();

    this.record && (this.filename_download = this.record.filename_download);
  }
};
</script>
